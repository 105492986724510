import 'bootstrap';

import { tns } from "tiny-slider/src/tiny-slider";

document.addEventListener("DOMContentLoaded", function () {
    const hpSlider = document.getElementsByClassName('hp-slider1')[0];

    if (hpSlider) {
        tns({
            container: '.hp-slider1',
            items: 1,
            loop: false,
            autoplay: false,
            navPosition: 'bottom',
            nav: false,
            controlsPosition: 'bottom',
            gutter: 25,
            mouseDrag: true,
            controlsText: ['', ''],
        });

    }


    const brandsSlider = document.getElementsByClassName('brands-slider')[0];

    if (brandsSlider) {
        tns({
            container: '.brands-slider',
            loop: true,
            center: true,
            autoplay: true,
            mouseDrag: true,
            nav: false,
            dots: false,
            autoplayButton: false,
            autoplayHoverPause: true,
            controls: false,
            responsive: {
                0: {
                    items: 2
                },
                576: {
                    items: 3
                },
                768: {
                    items: 5
                },
                992: {
                    items: 6
                }
            }
        });

    }


    const blogPostsSlider = document.getElementsByClassName('blog-posts-slider')[0];

    if (blogPostsSlider) {
        tns({
            container: '.blog-posts-slider',
            loop: true,
            autoplay: true,
            autoplayButton: false,
            navPosition: 'bottom',
            nav: false,
            controls: false,
            controlsPosition: 'bottom',
            gutter: 30,
            mouseDrag: true,
            controlsText: ['', ''],
            items: 1,
            responsive: {
                576: {
                    items: 2
                },
                992: {
                    items: 3
                }
            }
        });

    }
});
